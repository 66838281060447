
import axios, { AxiosError } from 'axios';
import { ICompanyData } from "@/store/CompaniesStore";
import { defineComponent, ref, onMounted, onBeforeMount, watch } from 'vue';
import { useRouter } from "vue-router";
import { useCompaniesStore } from "@/store/CompaniesStore";
import useAlert from "@/composables/Alert";
import {
  CampHeader,
  CampEmptyListFeedbackV2,
  CampTable,
  CampTableTd,
  CampTableTh
} from '@/components';
import { Modal } from "bootstrap";
import modalRelationStore from './Components/modalRelationStore.vue';
import { IUserData } from '@/store/UsersStore';
import { campHandleCNPJ } from '@/composables/DataValidation';
import { useLoaderStore } from "@/store/LoaderStore";
import { useCompanyStore } from '@/store/CompanyStore';
import CampModalDelRegister from '@/components/CampModalDelRegister.vue';
import { useAuthStore } from '@/store/AuthStore';
import SkeletonComponent from '@/components/SkeletonComponent.vue';


export interface ICompanyResponse {
  data: {
    error: boolean,
    message: string,
    data: ICompanyData[]
    errorCode: string
  }
}

export default defineComponent({
  name: "CompanyList",
  components: {
    CampEmptyListFeedbackV2,
    CampHeader,
    CampTable,
    CampTableTd,
    CampTableTh,
    modalRelationStore,
    CampModalDelRegister,
    SkeletonComponent
  },
  setup() {
    let auxModal;
    const loaderStore = useLoaderStore();
    const router = useRouter();
    const relationProps = ref(false)
    const relationData = ref<IUserData | null>(null)
    const authStore = useAuthStore()

    const queryObject = ref<{
      isLoading: boolean,
      noError: boolean,
      data:  null | ICompanyData[]
    }>({
      isLoading: false,
      noError: false,
      data: null
    })
    const printCompanies = ref<null | ICompanyData[]>(null)
    const keywordSearch = ref("")

    const companiesStore = useCompaniesStore()
    const { showTimeAlert } = useAlert()
    const itemDel = ref<ICompanyData | null>(null)
    const toggleModalDelRegister = ref(false)

    const companyStore = useCompanyStore()
    const companyName = ref("")
    const isLoading = ref(true)
    const isClickSelectCompanyLoad = ref(false)

    // Funcions
    const openModal = id => {
      const auxElement = document.querySelector(`#${id}`);
      auxModal = new Modal(auxElement);
      auxModal.show();
    };

    function funcDetailRelationData(data) {
      openModal('myModal')
      relationProps.value = !relationProps.value
      relationData.value = data
    }

    async function handleGetCompanies() {
      if(!isClickSelectCompanyLoad.value) {
        isLoading.value = true
      }
      try {
        const result: ICompanyResponse = await axios.get("/api/getCompanyList")
        const { data } = result
        if(!data.error) {
          const company = data.data.find(el => el.id === companyStore.getId)
          if(company) {
            const companies = [company, ...data.data.filter(el => el.id !== companyStore.getId)]
            queryObject.value.data = companies
            printCompanies.value = companies
            companiesStore.setCompanies(companies)
          } else {
            queryObject.value.data = data.data
            printCompanies.value = data.data
            companiesStore.setCompanies(data.data)
          }
        }
      } catch (error) {
        companiesStore.setCompanies(null)
      } finally {
        loaderStore.close()
        isLoading.value = false
        isClickSelectCompanyLoad.value = false
      }
    }

    function handleModalDelRegister(item: ICompanyData) {
      toggleModalDelRegister.value = !toggleModalDelRegister.value
      itemDel.value = item
    }

    async function handleConfirmItemDel() {
      if(itemDel.value) {
        loaderStore.open()
        try {
          const result = await axios.delete(`/api/deleteCompany/${itemDel.value.id}`)
          showTimeAlert(`O registro ${itemDel.value.fantasy_name} foi excluído com sucesso!`)
          handleGetCompanies()
          itemDel.value = null
          companyStore.setId(null)
          companyStore.setFantasyName("")
        } catch (error) {
          if(error instanceof AxiosError) {
            if(error.response) {
              showTimeAlert(error.response?.data.message, "error")
            }
          } else {
            showTimeAlert("Algo deu errado!", "error")
          }
        } finally {
          loaderStore.close()
        }
      }
    }

    const elementHTML = ref()

    function setCompanyInList(event, id) {
      try {
        isClickSelectCompanyLoad.value = true
        if((event.target.closest('#card_company_list_filter222') || event.target.tagName === 'TD') && queryObject.value && queryObject.value.data) {
            elementHTML.value = event.target.parentNode
              const company = printCompanies.value!.find(el => el.id === id)
                const companies: any = [company, ...queryObject.value.data.filter(el => el.id !== id).sort((a, b) => a.id - b.id)]
                printCompanies.value = companies
  
            companyStore.setId(id)
          }
      } catch (error) {
        
      } finally {

      }
    }

    function handleFilterObjectsByKeyword(): boolean {
      if(!queryObject.value.data)
        return true
      printCompanies.value = queryObject.value.data.filter((item) => {
        for (const key in item) {
          if (key !== 'checked') {
            const propValue = item[key];
            if (typeof propValue === 'string' && propValue.toLowerCase().includes(keywordSearch.value.toLowerCase())) {
              return true;
            }
            if (typeof propValue === 'number' && keywordSearch!.toString() === propValue.toString()) {
              return true;
            }
          }
        }
        return false;
      });
      return false
    }

    // Life Cycles

    onMounted(() => handleGetCompanies())

    watch(() => keywordSearch.value, () => handleFilterObjectsByKeyword())
    watch(() => companyStore.id, () => isLoading.value = true)
    watch(() => companyStore.company, () => handleGetCompanies())

    return {
      router,
      queryObject,
      printCompanies,
      keywordSearch,
      handleModalDelRegister,
      toggleModalDelRegister,
      itemDel,
      handleConfirmItemDel,
      relationData,
      funcDetailRelationData,
      relationProps,
      campHandleCNPJ,
      companyStore,
      companyName,
      isLoading,
      setCompanyInList,
      authStore
    }
  }
})
